require('./utils/bootstrap');
import store from './store/store'
import Vue from 'vue'
import '../public/assets/sass/app.scss'
// import '../public/assets/css/bootstrap-rtl.min.css'
// import '../public/assets/css/custom-style.css'
// import '../public/assets/css/vendors.css'

import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Vuetify from 'vuetify'
import Vuelidate from 'vuelidate'
import VueSocialSharing from 'vue-social-sharing'
import VueNumericInput from 'vue-numeric-input';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueFacebook from 'vue-facebook';
import router from './router/router'
import Mixin from './utils/mixin'
import init from './plugins/init'
import plugins from './plugins/plugins'
import { i18n } from './plugins/i18n'

import theShop from './components/TheShop';
import DynamicLink from './components/inc/DynamicLink';
import Banner from './components/inc/Banner';
import ProductBox from './components/product/ProductBox';
// async function fetchShopSetting() {
//   const response = await axios.get('https://www.gracemsk.jp/api/v1/setting/main');
//   if (response.data.success) {
//     return response.data.settings;
//   }
// }
// async function getInitialConfig() {
//   console.log('123')
//   try {
// 	const res = await axios.get('https://www.gracemsk.jp/api/v1/setting/main');
// 	if (res.data.success) {

// 		// commit('updateState', res.data.settings);
// 	}
//     return res.data;
//   } catch (error) {
//     console.error('Error fetching initial config:', error);
//   }
// }
// 确保在初始化其他部分之前获取配置
store.dispatch('app/fetchShopSetting').then(() => {
  console.log('成功')
  window.Vue = require('vue');
  Vue.use(VueNumericInput)
  Vue.use(VueSocialSharing)
  Vue.use(VueAwesomeSwiper)
  Vue.use(VueFacebook)
  Vue.use(plugins)
  Vue.use(VueRouter)
  Vue.use(Vuetify)
  Vue.use(VueMeta)
  Vue.use(Vuelidate)
  Vue.component('dynamic-link', DynamicLink)
  Vue.component('banner', Banner)
  Vue.component('product-box', ProductBox)
  Vue.mixin(Mixin)
  init(store,router)
  new Vue({
	store,
	i18n,
	router,
	vuetify: new Vuetify(),
	el: '#app',
	render: h => h(theShop),
  });
})
// async function initializeApp() {
//   await getInitialConfig(); // 等待配置加载
//   window.Vue = require('vue');
//   Vue.use(VueNumericInput)
//   Vue.use(VueSocialSharing)
//   Vue.use(VueAwesomeSwiper)
//   Vue.use(VueFacebook)
//   Vue.use(plugins)
//   Vue.use(VueRouter)
//   Vue.use(Vuetify)
//   Vue.use(VueMeta)
//   Vue.use(Vuelidate)
//   Vue.mixin(Mixin)
//   Vue.component('dynamic-link', DynamicLink)
//   Vue.component('banner', Banner)
//   Vue.component('product-box', ProductBox)
//   init(store,router)
//   new Vue({
// 	store,
// 	i18n,
// 	router,
// 	vuetify: new Vuetify(),
// 	el: '#app',
// 	render: h => h(theShop),
//   });
// }
// initializeApp()
